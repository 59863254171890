<div class="file-preview-toolbar">
  <div class="col-md-12">
    <span class="back-arrow">
      <!-- <i class="las la-arrow-left" (click)="closeToolbar()"></i> -->
      <button class="btn btn-sm blue-btn" (click)="closeToolbar()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </span>
    <span class="file-name"> {{documentInfo?.name}}</span>
    <button (click)="downloadDocument()" *ngIf="documentInfo?.isAllowDownload">
      <mat-icon>edit</mat-icon>
    </button>
    <!-- <span (click)="downloadDocument()" class="back-arrow float-right" *ngIf="documentInfo?.isAllowDownload">
      <i class="las la-arrow-down"></i>
    </span> -->
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="loading-shade" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
    <ng-container *ngIf="viewerType=='pdf'">
      <ngx-extended-pdf-viewer [base64Src]="documentUrl" [delayFirstView]="loadingTime" [showToolbar]="true"
        [showSidebarButton]="true" [showFindButton]="false" [showPagingButtons]="false" [showZoomButtons]="true"
        [showPresentationModeButton]="true" [showOpenFileButton]="false" [showPrintButton]="false"
        [showDownloadButton]="false" [showBookmarkButton]="false" [showSecondaryToolbarButton]="true"
        [showRotateButton]="true" [showHandToolButton]="false" [showScrollingButton]="true" [showSpreadButton]="false"
        [showPropertiesButton]="false" [useBrowserLocale]="true">
      </ngx-extended-pdf-viewer>
      <ng-template #customCheckboxZoomToolbar>
        <div id="toolbarViewer">
          <div [class.invisible]="false" id="toolbarViewerMiddle">
            <pdf-toggle-sidebar></pdf-toggle-sidebar>
            <pdf-page-number></pdf-page-number>
            <pdf-zoom-out></pdf-zoom-out>
            <pdf-zoom-in></pdf-zoom-in>
            <pdf-zoom-dropdown></pdf-zoom-dropdown>
            <pdf-rotate-page></pdf-rotate-page>
            <div class="float:right">
              <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="viewerType !=='pdf'">
      <div class="p-5">
        <ngx-doc-viewer [url]="documentUrl" [viewer]="viewerType" disableContent="all">
        </ngx-doc-viewer>
      </div>
    </ng-container>
  </div>
</div>
