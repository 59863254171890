<div class="close-btn-modal" (click)="onNoClick()">
  <i class="fa fa-times"></i>
</div>
<div class="container">
  <h3 mat-dialog-title>{{'CHANGE_PASSWORD' | translate}}</h3>
  <div mat-dialog-content>
    <form [formGroup]="changePasswordForm">
      <div class="col-md-12">
        <div class="form-group">
          <label>{{'EMAIL' | translate}}</label>
          <input [attr.disabled]="true" formControlName="email" class="form-control" type="email">
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-danger">{{'CURRENT_PASSWORD' | translate}} *</label>
          <input autocomplete="new-password" formControlName="oldPasswordPassword" class="form-control" type="password">
          <div
            *ngIf="changePasswordForm.get('oldPasswordPassword').touched && changePasswordForm.get('oldPasswordPassword').errors">
            <div *ngIf="changePasswordForm.get('oldPasswordPassword').errors?.required">
              {{'CURRENT_PASSWORD_IS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-danger">{{'NEW_PASSWORD' | translate}} *</label>
          <input autocomplete="new-password" formControlName="password" class="form-control" type="password">
          <div *ngIf="changePasswordForm.get('password').touched && changePasswordForm.get('password').errors">
            <div *ngIf="changePasswordForm.get('password').errors?.required">
              {{'PASSWORD_IS_REQUIRED' | translate}}
            </div>
            <div *ngIf="changePasswordForm.get('password').errors?.minlength">
              {{'YOU_HAVE_TO_ENTER_AT_LEAST_6_DIGIT' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-danger">{{'CONFIRM_PASSWORD' | translate}} *</label>
          <input autocomplete="new-password" formControlName="confirmPassword" class="form-control" type="password">
          <div
            *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').errors">
            <div *ngIf="changePasswordForm.get('confirmPassword').errors?.required">
              {{'CONFIRM_PASSWORD_IS_REQUIRED' | translate}}
            </div>
          </div>
          <div *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.hasError('notSame')">
            {{'PASSWORDS_DO_NOT_MATCH' | translate}}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <button class="btn btn-success btn-sm m-right-10" (click)="changePassword()" cdkFocusInitial><i
        class="fas fa-save"></i> {{'SAVE' | translate}}</button>
    <button class="btn btn-danger btn-sm" (click)="onNoClick()"><i class="fas fa-times-circle"></i> {{'CANCEL' |
      translate}}</button>
  </div>
</div>
